/* eslint-disable no-dupe-keys */
/* eslint-disable react/button-has-type */
/* eslint-disable no-underscore-dangle */
import { memo, useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Chip, Drawer, Stack, useMediaQuery, Button } from '@mui/material';
import plusIcon from '../../../assets/images/pngIcons/plus.png';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import MiniDrawerStyled from './MiniDrawerStyled';

import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'features/constant';

// import { useDispatch, useSelector } from 'features';
import { useDispatch, useSelector } from 'react-redux';
import { openDrawer, activeItem } from 'features/menu/menuSlice';
import useAuth from 'hooks/useAuth';
import { DEFAULT_BUTTON_COLOR_CODE } from 'config';
import { useNavigate } from 'react-router-dom';
import starIcon from '../../../assets/images/svgIcons/star.svg';

// import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
// import Header from './Header';
// import Sidebar from './Sidebar';
// // import Customization from '../Customization';
// import navigation from 'menu-items';
//
// import { useDispatch, useSelector } from 'react-redux';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = () => {
    const { dbUser, setStartNewCampaign } = useAuth();
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const { drawerType } = useConfig();

    const logo = useMemo(
        () => (
            <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                <LogoSection />
            </Box>
        ),
        []
    );

    const drawerContent = (
        <>
            <MenuList />
        </>
    );

    const drawerSX = {
        paddingLeft: drawerOpen ? '16px' : 0,
        paddingRight: drawerOpen ? '16px' : 0,
        marginTop: drawerOpen ? 20 : '42px'
    };
    const navigate = useNavigate();

    const handleAddNewCampaign = () => {
        dispatch(activeItem(['campaigns']));
        navigate('/campaigns');
        setStartNewCampaign(true);
    };

    const drawer = useMemo(
        () => (
            <>
                {drawerOpen && (
                    <div style={{ margin: '10px auto -20px' }}>
                        <Button
                            onClick={handleAddNewCampaign}
                            variant="outlined"
                            style={{
                                background: DEFAULT_BUTTON_COLOR_CODE,
                                color: '#fff',
                                display: 'flex',
                                width: '230px',
                                height: '45px'
                            }}
                        >
                            <img src={plusIcon} alt="plus" style={{ marginRight: '10px', fontSize: '20px', height: '20px' }} />
                            Create a new campaign
                        </Button>
                    </div>
                )}

                {matchDownMd ? (
                    <>
                        <Box style={{ ...drawerSX, marginTop: '20px' }}>
                            {/* <Box style={{ ...drawerSX, marginTop: '20px', height: 'calc(100vh - 310px)', overflow: 'auto', marginTop: '30px' }}> */}
                            {drawerContent}
                        </Box>
                    </>
                ) : (
                    <PerfectScrollbar
                        component="div"
                        style={{
                            height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                            ...drawerSX
                        }}
                    >
                        {drawerContent}
                    </PerfectScrollbar>
                    // <PerfectScrollbar
                    //     component="div"
                    //     style={{
                    //         height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 300px)',
                    //         ...drawerSX,
                    //         overflow: 'auto',
                    //         marginTop: '30px'
                    //     }}
                    // >
                    //     {drawerContent}
                    // </PerfectScrollbar>
                )}
                <div style={{ marginLeft: '20px' }}>
                    {(dbUser?._id && drawerOpen && (
                        <div
                            style={{
                                background: 'linear-gradient(to top, #f9f9f9, #ffecd0)',
                                border: '2px solid #fbdca7',
                                borderRadius: '8px',
                                width: '230px',
                                padding: '20px',
                                textAlign: 'center',
                                fontFamily: 'Arial, sans-serif',
                                position: 'absolute',
                                bottom: '10px',
                                height: '150px'
                            }}
                        >
                            <div
                                style={{
                                    background: 'white',
                                    height: '50px',
                                    width: '50px',
                                    borderRadius: '50%',
                                    textAlign: 'center',
                                    margin: '-45px auto 0'
                                }}
                            >
                                <img
                                    style={{ width: '40px', height: '40px', position: 'relative', top: '5px', left: '5px' }}
                                    src={starIcon}
                                    alt="starIcon"
                                />
                            </div>
                            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                You’ve left {dbUser.credit || '0'} credits. Do <br /> you want to upgrade?
                            </div>
                            <button
                                style={{
                                    background: '#f3ba26',
                                    width: '100%',
                                    padding: '10px',
                                    marginTop: '20px',
                                    borderRadius: '10px'
                                }}
                                onClick={() => navigate('/subscription')}
                            >
                                Upgrade
                            </button>
                        </div>
                    )) ||
                        ''}
                </div>
            </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [matchUpMd, drawerOpen, drawerType]
    );

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            {matchDownMd || (drawerType === LAYOUT_CONST.MINI_DRAWER && drawerOpen) ? (
                <Drawer
                    variant={matchUpMd ? 'persistent' : 'temporary'}
                    anchor="left"
                    open={drawerOpen}
                    onClose={() => dispatch(openDrawer(!drawerOpen))}
                    sx={{
                        '& .MuiDrawer-paper': {
                            mt: matchDownMd ? 0 : 11,
                            zIndex: 1099,
                            width: drawerWidth,
                            background: theme.palette.background.default,
                            color: theme.palette.text.primary,
                            borderRight: 'none'
                        }
                    }}
                    ModalProps={{ keepMounted: true }}
                    color="inherit"
                >
                    {matchDownMd && logo}
                    {drawer}
                </Drawer>
            ) : (
                <MiniDrawerStyled variant="permanent" open={drawerOpen}>
                    {logo}
                    {drawer}
                </MiniDrawerStyled>
            )}
        </Box>
    );
};

export default memo(Sidebar);
